// Import the edliy_utils
import {
    makeResponsive,
    placeTitle,
    placeImage,
    placeInput,
    placeSlider,
    hoverMe,
    placeRec,
    hiddenPt,
    fixedPt,
    clearInputFields,
    dragMe,
    placeArrow,
    placeGravity,
    placeMiddleText,
    placeLine,
    placeGlider,
    placeRuler,
    placeLeftText,
    placeRightText,
    placeSliderSwitch,
    placeCircle,
    placeLogo,
    placeShuffle,
    placeTest,
    placeUndo,
    placeRedo,
    placeBoldText,
    placeBoldLeftText,
    placeErase,
    drawPoint,
    setSize,
    createSpace
} from '../Utils';
const Boxes = {
  box1: function () {
	JXG.Options.board.minimizeReflow = 'none';
  JXG.Options.point.showInfoBox=false;
  JXG.Options.point.highlight=false;
  JXG.Options.image.highlight=false;
  JXG.Options.text.highlight=false;
  JXG.Options.text.fixed=true;
  JXG.Options.curve.highlight=false;
  JXG.Options.text.cssDefaultStyle='fontFamily:Oswald;'
  var brd1 = createSpace(-5,7,-7,5);
  //brd1.suspendUpdate();
  makeResponsive(brd1);
  brd1.options.layer['image'] =12;
  brd1.options.layer['circle'] =13;
  brd1.options.layer['chart'] =14;
  brd1.options.layer['sector'] =15;
  brd1.options.layer['line'] =15;
//	brd1.create('text', [6, 11.5, 'Fraction'],{highlight:false, display:'internal', anchorX:'middle', anchorY:'middle', CssStyle:'fontFamily:Oswald;fontWeight:bold',fontSize:function(){return Math.round(32*brd1.canvasWidth/800.)}, fixed:true});
  placeTitle(brd1, 'Toss of Two Coins', '');
  placeLogo(brd1);
  ////////////////////////////////////////////////////////////////////////////////
      var analytics = placeUndo(brd1,'left');
      analytics.setAttribute({visible:()=>i>0})
      analytics.setLabel('Tap to Go to Previous Step');
      analytics.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
      analytics.on('over', function () {this.label.setAttribute({visible:true});});
      analytics.on('out', function () {this.label.setAttribute({visible:false});});
      ////////////////////////////////////////////////////
      var erase = placeRedo(brd1);
      erase.setLabel('Tap to Go to Next Step')
      erase.setAttribute({visible:()=>i<3})
      erase.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
      erase.on('over', function () {this.label.setAttribute({visible:true});});
      erase.on('out', function () {this.label.setAttribute({visible:false});});
  /////////////////////////////////////////////////////////////////////////////
      var shuffle = placeShuffle(brd1,'left');
      shuffle.setAttribute({visible:()=>i==0})
      shuffle.setLabel('Tap to Shuffle Events');
      shuffle.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
      shuffle.on('over', function () {this.label.setAttribute({visible:true});});
      shuffle.on('out', function () {this.label.setAttribute({visible:false});});
///////////////////////////////////////////////////////////////////////////////////
      var shuffle2 = placeErase(brd1);
      shuffle2.setAttribute({visible:()=>i==3})
      shuffle2.setLabel('Tap to Start Over');
      shuffle2.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
      shuffle2.on('over', function () {this.label.setAttribute({visible:true});});
      shuffle2.on('out', function () {this.label.setAttribute({visible:false});});
      var i=0; var j=0; var N=4;
      var n=[2, 3, 1, 3, 4];
      var dice=[];
      var Hidd = drawPoint(brd1, -3, 6.5+3);
      setSize(brd1,Hidd,0);
      var Hid = drawPoint(brd1, -3, 6.5+6);
      setSize(brd1, Hid,0);
  //Red Dice
    dice[0] = placeImage(brd1, '/assets/dollarHead.svg', -3, ()=>Hidd.Y());//
   //dice[0].setAttribute({visible:()=>i>0});
   dice[1] =placeImage(brd1, '/assets/euroHead.svg', -3, ()=>Hidd.Y()-1);//
   //dice[1].setAttribute({visible:()=>i>0});
   dice[2] =placeImage(brd1, '/assets/dollarHead.svg', -1, ()=>Hidd.Y());//
   //dice[2].setAttribute({visible:()=>i>0});
   dice[3] =placeImage(brd1, '/assets/euroTail.svg', -1, ()=>Hidd.Y()-1);//
   //dice[3].setAttribute({visible:()=>i>0});
   dice[4] =placeImage(brd1, '/assets/dollarTail.svg', 1, ()=>Hidd.Y());//
   //dice[4].setAttribute({visible:()=>i>0});
   dice[5] =placeImage(brd1, '/assets/euroHead.svg', 1, ()=>Hidd.Y()-1);//
   dice[6] =placeImage(brd1, '/assets/dollarTail.svg', 3., ()=>Hidd.Y());//
   //dice[4].setAttribute({visible:()=>i>0});
   dice[7] =placeImage(brd1, '/assets/euroTail.svg', 3., ()=>Hidd.Y()-1);//
   //dice[5].setAttribute({visible:()=>i>0});
   var Event = [];
   Event[0] = placeBoldText(brd1, 1, 3.5, 'Probability of getting exactly one heads?');
   Event[0].setAttribute({visible:()=>j==0, color:'blue'});
   Event[1] = placeBoldText(brd1, 1, 3.5, 'Probability of getting atleast one heads?');
   Event[1].setAttribute({visible:()=>j==1, color:'blue'});
   Event[2] = placeBoldText(brd1, 1, 3.5, 'Probability of getting two heads?');
   Event[2].setAttribute({visible:()=>j==2, color:'blue'});
   Event[3] = placeBoldText(brd1, 1, 3.5, 'Probability of getting atleast one tails?');
   Event[3].setAttribute({visible:()=>j==3, color:'blue'});
   Event[4] = placeBoldText(brd1, 1, 3.5, 'Probability of getting two tails?');
   Event[4].setAttribute({visible:()=>j==4, color:'blue'});
//Step#1
   var eggOne = placeImage(brd1, '/assets/eggOne.svg',-4.25, 2.1);
   eggOne.setSize(0.8, 0.8);
   eggOne.setAttribute({visible:()=>i>0});
   var step1 = placeBoldLeftText(brd1, -3.25, 2.5, 'Start with all possible outcomes');
   step1.setAttribute({visible:()=>i>0});
   var Mvalue= placeBoldLeftText(brd1, 4.5, ()=>Hidd.Y(), 'N = 4');
   Mvalue.setAttribute({visible:()=>i>0});
//Step#2
  var eggTwo = placeImage(brd1, '/assets/eggTwo.svg',-4.25, -0.9);
  eggTwo.setAttribute({visible:()=>i>1});
  eggTwo.setSize(0.8, 0.8);
  var step2 = placeBoldLeftText(brd1, -3.25, -0.5, 'Find outcomes that belong to the event ');
  step2.setAttribute({visible:()=>i>1});
  var Nvalue= placeBoldLeftText(brd1, 4.5, ()=>Hid.Y(), ()=>'M = '+n[j]);
  Nvalue.setAttribute({visible:()=>i>0});
////////////////////////////////////////////////////////////////////////
//Step#3
  var eggThree = placeImage(brd1, '/assets/eggThree.svg',-4.25, -3.9);
  eggThree.setAttribute({visible:()=>i>2});
  eggThree.setSize(0.8, 0.8);
  var step3 =placeBoldLeftText(brd1, -3.25, -3.5, 'Find ratio M over N');
  step3.setAttribute({visible:()=>i>2});
///////////////////////////////////////////////////////////////////////////////
   var step4 = placeBoldText(brd1, 0, -4.5, ()=>'P(Event) = '+n[j]+'/'+N);
   step4.setAttribute({visible:()=>i>2});
///////////////////////////////////////////////////////////////////////////////

   var even=[]; var odd=[];
   even[0] = placeImage(brd1, '/assets/dollarHead.svg', -3, ()=>Hid.Y());//
   even[0].setAttribute({visible:()=>j==1 || j==2 });
   odd[0] = placeImage(brd1, '/assets/euroHead.svg', -3, ()=>Hid.Y()-1);
   odd[0].setAttribute({visible:()=>j==1 || j==2 });
   even[1] = placeImage(brd1, '/assets/dollarHead.svg', -1, ()=>Hid.Y());
   even[1].setAttribute({visible:()=>j==0 || j==1 || j==3});
   odd[1] = placeImage(brd1, '/assets/euroTail.svg', -1, ()=>Hid.Y()-1);
   odd[1].setAttribute({visible:()=>j==0 || j==1 || j==3});
   even[2] = placeImage(brd1, '/assets/dollarTail.svg', 1, ()=>Hid.Y());//
   even[2].setAttribute({visible:()=>j==0 || j==1 || j==3});
   odd[2] = placeImage(brd1, '/assets/euroHead.svg', 1, ()=>Hid.Y()-1);//
   odd[2].setAttribute({visible:()=>j==0 || j==1 || j==3});
   even[3] = placeImage(brd1, '/assets/dollarTail.svg',3, ()=>Hid.Y());//
   even[3].setAttribute({visible:()=>j==4 || j==3 });
   odd[3] = placeImage(brd1, '/assets/euroTail.svg', 3, ()=>Hid.Y()-1);//
   odd[3].setAttribute({visible:()=>j==4 || j==3});

///////////////////////////////TAP OPERATIONS ////////////////////////////////
analytics.on('down', function(){
  if(i==2){
  Hid.moveTo([-3, 6.5-1], 300);
  }
  if(i==1){
  Hidd.moveTo([-3, 6.5+6], 300);
  Hid.moveTo([-3, 6.5+6], 300);
  }
  if(i>0)
  {
  i-=1;
  }
  else
  {
    i=0;
  }
});
/////////////////////////////////////OPERATION 2 /////////////////////////////////
erase.on('down', function(){
  if(i==0){
  Hidd.moveTo([-3, 1], 300);
  }
  if(i==1){
  Hid.moveTo([-3, -2], 300);
  }
  else if(i==0)
  {
  Hid.moveTo([-3, 8.5-1], 300);
  }
  if(i<3)
  {
  i+=1;
  }
  else
  {
    i=3;
  }
})
///////////////////////////////////////////OPERATION 3 //////////////////////////////////////
shuffle.on('down', function(){
  Hidd.moveTo([-3, 6.5+6], 300);
  Hid.moveTo([-3, 6.5+6], 300);
  i=0;
  if(j<4)
  {
  j+=1;
  }
  else
  {
  j=0;
  }
})
shuffle2.on('down', function(){
  Hidd.moveTo([-3, 6.5+6], 300);
  Hid.moveTo([-3, 6.5+6], 300);
  i=0;
})
////////////////////////////////////////////END OF OPERATIONS///////////////////////////////
//brd1.unsuspendUpdate();
},
}
export default Boxes;
